import type { DocNode } from '@atlaskit/adf-schema';

export const parseBannerContent = (content?: string): DocNode => {
	if (!content) return {} as DocNode;
	try {
		return JSON.parse(content) as DocNode;
	} catch {
		// TODO: Add Experience Tracking here
		return {} as DocNode;
	}
};
