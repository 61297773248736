import { useState, useEffect, useMemo } from 'react';

import {
	confluenceLocalStorageInstance as localStorage,
	confluenceSessionStorageInstance as sessionStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

const ADMIN_ANNOUNCEMENT_BANNER_DISMISS_STATE_KEY = 'admin.announcement.banner';
const PERSISTED_ADMIN_ANNOUNCEMENT_BANNER_DISMISS_STATE_KEY =
	PERSISTED_KEYS_ON_SERVER.PERSISTED_ADMIN_ANNOUNCEMENT_BANNER;

type DismissalStateParam = {
	isLicensed: boolean;
	id?: string;
	updatedAt?: string;
};

export const getIsDismissed = ({
	cachedUpdatedAt,
	updatedAt,
}: {
	cachedUpdatedAt?: string;
	updatedAt?: string;
}) => {
	if (!updatedAt) return false;
	return cachedUpdatedAt === updatedAt;
};

export const useDismissalState = ({ isLicensed, id, updatedAt }: DismissalStateParam) => {
	const { storage, dismissStateKey, initialUpdatedAt } = useMemo(() => {
		if (!isLicensed) {
			return {
				storage: sessionStorage,
				dismissStateKey: ADMIN_ANNOUNCEMENT_BANNER_DISMISS_STATE_KEY,
			};
		}

		return {
			storage: localStorage,
			dismissStateKey: PERSISTED_ADMIN_ANNOUNCEMENT_BANNER_DISMISS_STATE_KEY,
			initialUpdatedAt: localStorage?.getItem(PERSISTED_ADMIN_ANNOUNCEMENT_BANNER_DISMISS_STATE_KEY)
				?.updatedAt,
		};
	}, [isLicensed]);

	const [isDismissed, setIsDismissed] = useState<boolean | undefined>(
		getIsDismissed({ cachedUpdatedAt: initialUpdatedAt, updatedAt }),
	);

	useEffect(() => {
		if (updatedAt) {
			const cachedUpdatedAt = storage.getItem(dismissStateKey)?.updatedAt;
			setIsDismissed(getIsDismissed({ cachedUpdatedAt, updatedAt }));
		}
	}, [dismissStateKey, storage, updatedAt]);

	const onDismiss = () => {
		storage.setItem(dismissStateKey, { id, updatedAt });
		const cachedUpdatedAt = storage.getItem(dismissStateKey)?.updatedAt;
		setIsDismissed(getIsDismissed({ cachedUpdatedAt, updatedAt }));
	};

	return { isDismissed, onDismiss };
};
