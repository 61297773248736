import type { DocNode } from '@atlaskit/adf-schema';

import type { AdfNode } from './contentOverflowPlugin';
import { getCharacterCount } from './contentOverflowPlugin';
import { parseBannerContent } from './parseBannerContent';
import { MAX_CONTENT_CHARACTER_COUNT_SINGLE_LINE } from './validateContent';

// Note: This check relies on MAX_CONTENT_CHARACTER_COUNT_SINGLE_LINE, which is an approximation for how many
// characters fit on a single line within a banner.
export const isCharacterCountSingleLine = (characterCount: number) =>
	characterCount <= MAX_CONTENT_CHARACTER_COUNT_SINGLE_LINE;

export const isBannerContentSingleLineFromDocNode = (docNode: DocNode) => {
	// DocNode with an empty content property corresponds with empty banner content, which is valid and is a single line
	if (!docNode.content) {
		return true;
	}
	const nodes = docNode.content as AdfNode[];
	const characterCount = getCharacterCount(nodes);
	return isCharacterCountSingleLine(characterCount);
};

export const isBannerContentSingleLineFromJSONString = (content: string) => {
	const docNode = parseBannerContent(content);
	return isBannerContentSingleLineFromDocNode(docNode);
};
