import {
	ADMIN_ANNOUNCEMENT_BANNER_SEGMENT_KEY,
	ADMIN_ANNOUNCEMENT_BANNER_SETTING_PAGE_LOAD_KEY,
	createPageLoadMetric,
	createPageSegmentLoadMetric,
} from '@confluence/browser-metrics';

export const ADMIN_ANNOUNCEMENT_BANNER_METRIC = createPageSegmentLoadMetric({
	key: ADMIN_ANNOUNCEMENT_BANNER_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});

export const ADMIN_ANNOUNCEMENT_BANNER_SETTING_PAGE_LOAD = createPageLoadMetric({
	key: ADMIN_ANNOUNCEMENT_BANNER_SETTING_PAGE_LOAD_KEY,
});
