import type { ApolloError } from 'apollo-client';
import { isApolloError } from 'apollo-client';

import { getStatusCode } from '@confluence/error-boundary';

export const shouldMarkErrorAsHandled = (error: ApolloError | Error | undefined): boolean => {
	if (!error) return true;
	const statusCode = isApolloError(error)
		? getStatusCode(error?.graphQLErrors?.[0])
		: getStatusCode(error);
	if (!statusCode) return true;
	return !!(parseInt(statusCode) >= 400 && parseInt(statusCode) < 500);
};
