import { useState, useCallback } from 'react';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

const getIsDismissed = () => {
	return (
		String(
			localStorage?.getItem(
				PERSISTED_KEYS_ON_SERVER.PERSISTED_ADMIN_ANNOUNCEMENT_BANNER_MIGRATOR_PROMPT,
			),
		) === 'true'
	);
};
// Migrator Admin Prompt Banner Dismissal State Hook
export const useMigratorPromptDismissalState = () => {
	const [isDismissed, setIsDismissed] = useState<boolean>(getIsDismissed);

	const onDismiss = useCallback(() => {
		localStorage.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_ADMIN_ANNOUNCEMENT_BANNER_MIGRATOR_PROMPT,
			'true',
		);
		setIsDismissed(true);
	}, []);

	return { isDismissed, onDismiss };
};
