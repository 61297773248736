import { filter as adfFilter } from '@atlaskit/adf-utils/traverse';
import type { DocNode } from '@atlaskit/adf-schema';

import type { AdfNode } from './contentOverflowPlugin';
import { getCharacterCount } from './contentOverflowPlugin';

/** Changing these values would require changes in the backend */
export const MAX_CONTENT_CHARACTER_COUNT_SINGLE_LINE = 180;
export const MAX_TOTAL_CONTENT_CHARACTER_COUNT = 4000;
export const MAX_TITLE_CHARACTER_COUNT = 40;

export enum ContentValidationError {
	INVALID_NODE = 'invalidNode',
	EMPTY = 'empty',
	CHARACTER_OVERFLOW = 'characterOverflow',
}

export enum SchedulingValidationError {
	START_AFTER_END_DATE = 'startAfterEndDate',
	START_AFTER_END_TIME = 'startAfterEndTime',
	END_BEFORE_START_DATE = 'endBeforeStartDate',
	END_BEFORE_START_TIME = 'endBeforeStartTime',
	START_DATE_CONFLICT = 'startDateConflict',
	START_TIME_CONFLICT = 'startTimeConflict',
	END_DATE_CONFLICT = 'endDateConflict',
	END_TIME_CONFLICT = 'endTimeConflict',
}

export const validateTitle = (value: string | undefined) => {
	if (!value || value.length === 0 || !value.replace(/\s/g, '').length) {
		return 'empty';
	}
	return undefined;
};

export const validateContent = (adfDoc: any): ContentValidationError | null => {
	if (!adfDoc?.content) {
		return ContentValidationError.EMPTY;
	}

	if (adfDoc.content.length !== 1 || adfDoc.content[0].type !== 'paragraph') {
		return ContentValidationError.INVALID_NODE;
	}

	if (!adfDoc.content[0].content) {
		return ContentValidationError.EMPTY;
	}

	const characterCount = getCharacterCount(adfDoc.content as AdfNode[]);
	if (characterCount == 0) {
		return ContentValidationError.EMPTY;
	}

	// when text is all whitespaces, the content is considered empty
	const texts = adfFilter(adfDoc as DocNode, (node) => node.type === 'text');
	if (texts.length === 1 && texts[0].text!.trim().length === 0) {
		return ContentValidationError.EMPTY;
	}

	if (characterCount > MAX_TOTAL_CONTENT_CHARACTER_COUNT) {
		return ContentValidationError.CHARACTER_OVERFLOW;
	}

	return null;
};
