import gql from 'graphql-tag';

export const AABMigratorPromptQuery = gql`
	query AABMigratorPromptQuery {
		adminAnnouncementBannerSettingsByCriteria(first: 1, orderBy: DEFAULT, isAscending: false) {
			nodes {
				id
			}
		}
	}
`;
