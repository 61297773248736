import { useEffect } from 'react';

import { getMonitoringClient } from '@confluence/monitoring';

export const useSubmitSSRScriptErrors = (scope: string) => {
	useEffect(() => {
		if (!window.__SSR_CLIENT_ERRORS__ || !window.__SSR_CLIENT_ERRORS__[scope]) {
			return;
		}

		const ssrErrors = window.__SSR_CLIENT_ERRORS__[scope];
		ssrErrors.forEach((error) =>
			getMonitoringClient().submitError(error, { attribution: 'backbone' }),
		);
		delete window.__SSR_CLIENT_ERRORS__[scope];
	}, [scope]);
};
