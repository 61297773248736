import { defineMessages, useIntl } from 'react-intl-next';

import type { DocNode, LinkDefinition } from '@atlaskit/adf-schema';

// Type definition for MigratorPrompt
type MigratorPrompt = {
	id: string;
	appearance: string;
	isDismissible: boolean;
	content: string;
	updatedAt: string;
	title: string;
};
// i18n messages
const i18n = defineMessages({
	promptContentText: {
		id: 'admin-announcement-banner.migrator-prompt.content',
		defaultMessage:
			'Guess what, admins? You can use an announcement banner like this one to communicate important messages to all of your users at once.',
		description:
			'Text for the migrator prompt in the admin banner, prompting users to use a new feature. Optimal characters is 150 or less.',
	},
	promptLinkText: {
		id: 'admin-announcement-banner.migrator-prompt.link',
		defaultMessage: 'Create announcement banner',
		description:
			'Link text in the admin banner, leading to the creation of a new announcement banner. Optimal characters is 32 or less.',
	},
});

// Default migrator prompt with empty content
export const migratorPromptWithEmptyContent: MigratorPrompt = {
	id: 'migratorPrompt',
	isDismissible: true,
	appearance: 'note',
	title: 'Migrator Prompt AAB',
	updatedAt: new Date().toUTCString(),
	content: '',
};

// Hook to generate the content for the migrator prompt
export const useMigratorPromptContent = (): DocNode => {
	const { formatMessage } = useIntl();

	return {
		version: 1,
		type: 'doc',
		content: [
			{
				type: 'paragraph',
				content: [
					{ type: 'text', text: formatMessage(i18n.promptContentText) },
					{ type: 'text', text: ' ' },
					{
						type: 'text',
						text: formatMessage(i18n.promptLinkText),
						marks: [
							{
								type: 'link',
								attrs: { href: '/wiki/admin/announcements' },
							} as LinkDefinition,
						],
					},
					{ type: 'text', text: '.' },
				],
			},
		],
	};
};
