import { useQuery } from '@apollo/react-hooks';

import { useSessionData } from '@confluence/session-data';
import { ssrBannerManager } from '@confluence/ssr-utilities';
import type { BannerStateContainer } from '@confluence/banners';

import { isBannerContentSingleLineFromJSONString } from './isBannerContentSingleLine';
import { AdminAnnouncementBannerQuery } from './queries/AdminAnnouncementBannerQuery.graphql';
import type { AdminAnnouncementBannerQuery as AdminAnnouncementBannerQueryData } from './queries/__types__/AdminAnnouncementBannerQuery';
import { useDismissalState } from './useDismissalState';
import { useIsPageEligibleForAdminAnnouncementBanner } from './useIsPageEligibleForAdminAnnouncementBanner';
import { useMigratorPromptEligible } from './useMigratorPromptEligible';
import { useMigratorPromptDismissalState } from './useMigratorPromptDismissalState';

const BANNER_NAME = 'AdminAnnouncementBanner';
export const BANNER_HEIGHT_SINGLE_LINE = 48;
export const BANNER_HEIGHT_MULTI_LINE = 72;

export function useRegisterAdminAnnouncementBanner(
	bannerState: BannerStateContainer | null = null,
) {
	const { isLicensed } = useSessionData();
	const isPageEligibleForAdminAnnouncementBanner = useIsPageEligibleForAdminAnnouncementBanner();

	const {
		data,
		loading: activeAnnouncementBannerLoading,
		error: activeAnnouncementBannerError,
	} = useQuery<AdminAnnouncementBannerQueryData>(AdminAnnouncementBannerQuery, {
		skip: !isPageEligibleForAdminAnnouncementBanner,
	});

	const hasOtherBanners =
		isPageEligibleForAdminAnnouncementBanner &&
		bannerState?.state?.banners?.some((banner) => banner.name !== BANNER_NAME);

	// Migrator prompt eligibility
	const { isMigratorPromptEligible, loading: migratorPromptEligibleLoading } =
		useMigratorPromptEligible({
			skip:
				// No SSR support for migrator prompt
				Boolean(process.env.REACT_SSR) ||
				// Contextual eligibility check
				!isPageEligibleForAdminAnnouncementBanner ||
				hasOtherBanners ||
				// Existing active AAB Query
				activeAnnouncementBannerLoading ||
				Boolean(data?.adminAnnouncementBanner),
		});

	// Dismissal state
	const migratorPromptDismissalState = useMigratorPromptDismissalState();
	const adminAnnouncementDismissalState = useDismissalState({
		isLicensed,
		id: data?.adminAnnouncementBanner?.id,
		updatedAt: data?.adminAnnouncementBanner?.updatedAt,
	});

	const { isDismissed, onDismiss } = isMigratorPromptEligible
		? migratorPromptDismissalState
		: adminAnnouncementDismissalState;

	const loading = activeAnnouncementBannerLoading || migratorPromptEligibleLoading;

	// Banner rendering conditions
	const bannerHasContent = Boolean(data?.adminAnnouncementBanner) || isMigratorPromptEligible;
	const isBannerReadyToDisplay = !loading && !hasOtherBanners && !isDismissed;

	const shouldRender = bannerHasContent && isBannerReadyToDisplay;
	const shouldRenderMigratorPrompt = isMigratorPromptEligible && isBannerReadyToDisplay;

	if (shouldRender) {
		const bannerHeight = isBannerContentSingleLineFromJSONString(
			data?.adminAnnouncementBanner?.content || '',
		)
			? BANNER_HEIGHT_SINGLE_LINE
			: BANNER_HEIGHT_MULTI_LINE;
		bannerState?.show(BANNER_NAME, bannerHeight);
		if (process.env.REACT_SSR) {
			ssrBannerManager.registerBanner({
				name: BANNER_NAME,
				height: bannerHeight,
			});
		}
	} else {
		bannerState?.hide(BANNER_NAME);
	}

	return {
		shouldRender,
		hasOtherBanners,
		onDismiss,
		data,
		loading,
		error: activeAnnouncementBannerError,
		shouldRenderMigratorPrompt,
	};
}
